<template>
	<v-container fluid>
		<v-row dense>
			<v-col>
				<session-select
					:disabled="invalidUtility"
					:utility="selectedUtility"
					v-model="selectedSession"
					:session.sync="sessionRow"
					clearable
					dense outlined hide-details single-line label="Сесия"
					style="max-width: 400px"
				/>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col>
				<ep-list style="max-width: 600px">

					<template v-for="(def, index) in reportDefs">
						<ep-panel  :key="index" v-if="!def.for_utility || selectedUtility && def.for_utility.includes(selectedUtility)">
							<ep-header>{{def.group_title}}</ep-header>
							<ep-content>
								<template v-for="(item, index2) in def.items">
									<v-divider :key="`sep-${index}-${index2}`" class="my-2" v-if="def.items.length > 1" />
									<p :key="`desc-${index}-${index2}`" v-if="item.description != ''" v-text="item.description" />
									<v-alert :key="`alert-${index}-${index2}`" type="error" text dense v-if="!item.no_utility_session && invalidUtilitySession">Изберете дружество и сесия</v-alert>
									<div class="text-right" :key="`btn-${index}-${index2}`">
										<download-btn-with-progress
											v-if="item.progress"
											:button-props="{
												small: true,
												color: 'primary',
												disabled: !item.no_utility_session && invalidUtilitySession,
												width: downloadBtnWidth,
											}"
											:rest-service="()=>download(item)"
										>
											<template #button-content>
												Свали
											</template>
										</download-btn-with-progress>
										<download-btn
											v-else
											small
											:width="downloadBtnWidth"
											label="Свали"
											:disabled="!item.no_utility_session && invalidUtilitySession"
											:rest-service="()=>download(item)"
										/>
									</div>
								</template>
							</ep-content>
						</ep-panel>
					</template>

				</ep-list>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import SessionSelect from "@/views/sessions/SessionSelect.vue";
import DownloadBtn from "@/ittijs/components/DownloadBtn.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import ReportsModel from "@/views/reports/Model";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import DownloadBtnWithProgress from "@/ittijs/components/DownloadBtnWithProgress.vue";

const reportsModel = ITTIModelProvider.getModel(ReportsModel);

export default {
	components: {DownloadBtnWithProgress, DownloadBtn, SessionSelect},
	mixins: [
		SelectedUtilityMixin,
		expansionPanelShort,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
	],
	data(){
		return {
			selectedSession: null,
			sessionRow: null,
			downloadBtnWidth: 100,
			reportsModel,
		}
	},
	computed: {
		invalidUtility(){
			return !this.selectedUtility;
		},
		invalidUtilitySession(){
			return this.invalidUtility || !this.selectedSession;
		},
		reportDefs(){
			return this.reportsModel.modelData?.reportDefs;
		},
	},
	methods: {
		download(reportDef){
			const params = reportDef.no_utility_session ? {} : {
				utility: this.selectedUtility,
				session: this.selectedSession,
			};
			return reportsModel.fetch(reportDef.rest, params);
		},
	},
}
</script>